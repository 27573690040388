import React, { useState } from 'react';
import { string } from 'prop-types';

import { Box, Text, Heading, Center, Button, useToast, Divider } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import ModalBank from './ModalBank';
import ModalAddress from './ModalAddress';
import ModalConfirmation from './ModalConfirmation';

import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import useInvitation from '@hooks/useInvitation';
import txtWording from './locales';

import {
  ENABLE_SEND_GIFT,
  ENABLE_BANK_TRANSFER,
  ENABLE_GIFT_CONFIRMATION,
  ENABLE_RSVP,
  DEFAULT_LANGUAGE,
} from '@/constants/feature-flags';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  idle: '',
  confirm: 'confirmation',
};

const DEFAULT_BUTTON = {
  size: 'sm',
  fontWeight: 'normal',
  color: 'mainColorText',
  bgColor: 'bgSecondary',
  // textTransform: 'uppercase',
  // letterSpacing: '2px',
  ...DEFAULT_BUTTON_PROPS,
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ lang, ...rest }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();
  const isInvitation = useInvitation();
  const invitation = ENABLE_RSVP && isInvitation;

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txtWording.success[lang],
        description: message || txtWording.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Ooops!',
        description: txtWording.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box bgColor="bgPrimary">
      <Box bgColor="bgPrimary" paddingBottom={invitation ? '18px' : ''} {...rest}>
        <Box position="absolute" maxW="500px" width="100%">
          <Divider
            position="absolute"
            right="0"
            maxW="45%"
            borderBottomColor="mainColorText"
            opacity={1}
            borderBottomWidth="3px"
            zIndex={2}
          />
        </Box>
        <Box
          padding="42px 32px"
          bgRepeat="no-repeat"
          width="100%"
          bgSize="100% 100%"
          bgColor="bgPrimary"
          borderRadius="16px"
        >
          <Center>
            <WithAnimation>
              <Heading size="2xl" color="mainColorText" fontWeight="normal">
                {txtWording.title[lang]}
              </Heading>
            </WithAnimation>
          </Center>
          <WithAnimation>
            <Text color="mainColorText" align="center" margin="16px 0">
              {txtWording.desc[lang]}
            </Text>
          </WithAnimation>
          {ENABLE_BANK_TRANSFER && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button {...DEFAULT_BUTTON} onClick={() => setModalState(MODAL_STATE.bank)}>
                  Bank Transfer
                </Button>
              </WithAnimation>
            </Center>
          )}
          {/* Send gift button section */}
          {ENABLE_SEND_GIFT && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button {...DEFAULT_BUTTON} onClick={() => setModalState(MODAL_STATE.address)}>
                  {txtWording.sendGift[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
          {ENABLE_GIFT_CONFIRMATION && (
            <Center marginTop="12px">
              <WithAnimation>
                <Button {...DEFAULT_BUTTON} onClick={() => setModalState(MODAL_STATE.confirm)}>
                  {txtWording.confirm[lang]}
                </Button>
              </WithAnimation>
            </Center>
          )}
        </Box>
        {invitation && (
          <Box position="absolute" maxW="500px" width="100%">
            <Divider maxW="45%" borderBottomColor="mainColorText" opacity={1} borderBottomWidth="3px" />
          </Box>
        )}
      </Box>
      <ModalBank
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.bank}
        onCopyText={onCopyText}
      />
      <ModalAddress
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.address}
        onCopyText={onCopyText}
      />
      <ModalConfirmation
        lang={lang}
        isOpen={modalState === MODAL_STATE.confirm}
        onClose={onClose}
      />
    </Box>
  );
}

GiftCardSection.propTypes = {
  lang: string,
};

GiftCardSection.defaultProps = {
  lang: DEFAULT_LANGUAGE,
};

export default React.memo(GiftCardSection);
